<template>
    <modal ref="modalVerProducto" titulo="Producto" icon="box" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0 justify-content-center align-items-center">
            <i class="f-18" :class="`icon-${producto.favorito ? 'star orange-star' : 'star-empty'}`" />
            <div class="col-auto">
                <img :src="producto.imagen" width="77" height="77" class="obj-cover" />
                <span class="text-general2 f-15 tres-puntos">
                    {{ producto.detalle_producto }}
                </span>
            </div>
            <div class="col-auto">
                <p class="text-general f-16 f-600 mb-3">Compras al proveedor</p>
                <div class="row mx-0">
                    <span class="text-general2 f-15">{{ separadorNumero(producto.compras) }}</span>
                    <div class="col-6 px-2">
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                                <div class="row mx-0 my-2">
                                    <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ agregarSeparadoresNumero(producto.porcentaje_compras_abastecimiento) }}%</span>
                                    <span class="ml-2">Abastecimiento</span>
                                </div>
                                <div class="row mx-0">
                                    <div class="bg-df br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ agregarSeparadoresNumero(producto.porcentaje_compras_retiro) }}%</span>
                                    <span class="ml-2">Retiro</span>
                                </div>
                            </div>
                            <div class="col-6 px-0">
                                <div class="row mx-0 br-5 border" style="height:18px;">
                                    <div class="h-100 bg-general2" :class="`${producto.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(producto.porcentaje_compras_abastecimiento)}%`" />
                                    <div class="h-100 bg-df" :class="`${producto.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(producto.porcentaje_compras_retiro)}%`" />
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-16 f-600 mb-3">Compras a otros proveedores</p>
                <div class="row mx-0">
                    <span class="text-general2 f-15">{{ separadorNumero(producto.compras_otros) }}</span>
                    <div class="col-6 px-2">
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                                <div class="row mx-0 my-2">
                                    <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ agregarSeparadoresNumero(producto.porcentaje_compras_otros_abastecimiento) }}%</span>
                                    <span class="ml-2">Abastecimiento</span>
                                </div>
                                <div class="row mx-0">
                                    <div class="bg-df br-5" style="width:18px;height:18px;" />
                                    <span class="mx-2">{{ agregarSeparadoresNumero(producto.porcentaje_compras_otros_retiro) }}%</span>
                                    <span class="ml-2">Retiro</span>
                                </div>
                            </div>
                            <div class="col-6 px-0">
                                <div class="row mx-0 br-5 border" style="height:18px;">
                                    <div class="h-100 bg-general2" :class="`${producto.porcentaje_compras_otros_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(producto.porcentaje_compras_otros_abastecimiento)}%`" />
                                    <div class="h-100 bg-df" :class="`${producto.porcentaje_compras_otros_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(producto.porcentaje_compras_otros_retiro)}%`" />
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-16 f-600 mb-3">Stock Total</p>
                <p class="text-general2 f-16 text-center">
                    {{ agregarSeparadoresNumero(producto.stocks) }}
                </p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general :data="dataTable" :columnas="dataColumns" :alto="altoTabla" :mostrar-buscador="false">
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        class-name="text-center"
                        width="60"
                        >
                            <template slot-scope="{ row }">
                                <el-tooltip v-if="!row.estado" placement="bottom" content="Inactivo" effect="light">
                                    <i class="icon-eye-off f-22 text-general hover-icons" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="60"
                        >
                            <template slot-scope="{ row }">
                                <el-tooltip v-if="row.inventario_proveedor" placement="bottom" content="El inventario lo tiene directamente el proveedor" effect="light">
                                    <i class="icon-package-variant f-22 text-general hover-icons" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </modal>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores_estadisticas";
export default {
    data(){
        return {
            dataColumns: [
                { valor: 'cedis', titulo:"Cedis", class: 'text-general f-16 text-center' },
                { valor: 'proveedor', titulo:"Proveedor", class: 'text-general f-16 text-center' },
                { valor: 'ultimo_compra', titulo:"Última compra", class: 'text-general f-16 text-center' },
                { valor: 'ultima_compra_fecha', titulo:"Fecha Últ.compra", class: 'text-general f-16 text-center' },
                { valor: 'stocks', titulo:"Stock", class: 'text-general f-16 text-center' },
                { valor: 'ultimo_costo', titulo:"Últ. Costo", class: 'text-general f-16 text-center' },
                { valor: 'valor', titulo:"Valor de venta", class: 'text-general f-16 text-center' },
                { valor: 'ventas', titulo:"Ventas", class: 'text-general f-16 text-center' },
            ],
            dataTable: [],
            altoTabla: 'calc(100vh - 650px)',
            producto: {
                id: null,
                compras_abastecimiento: null,
                compras_retiro: null,
                compras_abastecimiento_otros: null,
                compras_retiro_otros: null,
                ultimo_abastecimiento: null,
                ultimo_retiro: null,
                ultimo_costo_abastecimiento: null,
                ultimo_costo_retiro: null,
                stocks: null,
                favorito: null,
                compras: null,
                compras_otros: null,
                ultima_compra: null,
                ultimo_costo: null,
                porcentaje_compras_abastecimiento: null,
                porcentaje_compras_retiro: null,
                porcentaje_compras_otros_abastecimiento: null,
                porcentaje_compras_otros_retiro: null,
                imagen: null,
                detalle_producto: null,
            },
            id_moneda: null,
            fecha_inicio: null,
            fecha_fin: null,
        }
    },
    created(){
        this.changeSizeModal();
    },
    methods: {
        toggle(datos){
            console.log(datos.row);
            this.producto = _.cloneDeep(datos.row)
            this.id_moneda = datos.id_moneda
            this.fecha_inicio = datos.fecha_inicio
            this.fecha_fin = datos.fecha_fin
            this.modalProductoProveedor()
            this.$refs.modalVerProducto.toggle();
        },
        changeSizeModal(){
            console.log('este es un ejemplo');
            this.altoTabla = window.innerHeight <= 680 ? 'calc(100vh - 511px)' : 'calc(100vh - 650px)'
        },
        async modalProductoProveedor(){
            try {
                let params =  {
                    id: this.producto.id,
                    fecha_inicio: this.fecha_inicio,
                    fecha_fin: this.fecha_fin,
                    id_moneda: this.id_moneda,
                }
                const {data} = await Proveedor.modalProductoProveedor(params)
                data.cedisProductos.map(el => {
                    el.ultimo_compra = this.separadorNumero(el.ultimo_compra)
                    el.ultimo_costo = this.separadorNumero(el.ultimo_costo)
                    el.valor = this.separadorNumero(el.valor)
                    el.ventas = this.separadorNumero(el.ventas)
                    el.stocks = this.agregarSeparadoresNumero(el.stocks)
                    el.ultima_compra_fecha = this.formatearFecha(el.ultima_compra_fecha)
                    el.proveedor = el.proveedor || '----'
                })
                this.dataTable = data.cedisProductos
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-df{
    background-color: #DFDFDD !important;
}
.orange-star{
    color: #FF9D32;
}
</style>
